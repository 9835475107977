module.exports = {
	siteUrl: 'https://www.envel.ai', // Site domain. Do not include a trailing slash!

	postsPerPage: 9, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

	siteTitleMeta: 'Envel - The World’s Smartest Bank Account', // This allows an alternative site title for meta data for pages.
	siteDescriptionMeta: 'The first ever AI-based Autonomous Bank Account that gives you Financial Superpowers!', // This allows an alternative site description for meta data for pages.

	shareImageWidth: 1200, // Change to the width of your default share image
	shareImageHeight: 630, // Change to the height of your default share image

	shortTitle: 'Envel', // Used for App manifest e.g. Mobile Home Screen
	siteIcon: 'favicon.png', // Logo in /static dir used for SEO, RSS, and App manifest
	backgroundColor: '#e9e9e9', // Used for Offline Manifest
	themeColor: '#15171A', // Used for Offline Manifest
};
