import React from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import { useLocation } from '@reach/router';

import config from '../../utils/siteConfig';
import WebsiteMeta from './WebsiteMeta';

/**
 * MetaData will generate all relevant meta data information incl.
 * JSON-LD (schema.org), Open Graph (Facebook) and Twitter properties.
 *
 */
const MetaData = ({ settings, title, description, image }) => {
	const location = useLocation();
	const canonical = url.resolve(config.siteUrl, location.pathname);
	settings = {};

	title = title || config.siteTitleMeta || settings?.title;
	description = description || config.siteDescriptionMeta || settings?.description;
	// eslint-disable-next-line camelcase
	image = image || settings?.cover_image || null;

	image = image ? url.resolve(config.siteUrl, image) : null;

	return (
		<WebsiteMeta data={{}} canonical={canonical} title={title} description={description} image={image} type="WebSite" />
	);
};

MetaData.defaultProps = {
	data: {},
};

MetaData.propTypes = {
	settings: PropTypes.shape({
		allGhostSettings: PropTypes.object.isRequired,
	}).isRequired,
	// location: PropTypes.shape({
	// 	pathname: PropTypes.string.isRequired,
	// }).isRequired,
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
};

export default MetaData;
